$separator-color-light: #f3f3f3;
$separator-color: #d7d7d7;
$background-color: #f8f8f8;
$foreground-color : white;
$input-background: white;

$dark-btn-background: #131113;
$light-btn-background: #ececec;

$button-text-color: #fff;

$theme-color-1: #FF6905;
$theme-color-2: #e7523e;
$theme-color-3: #d6a779;
$theme-color-4: #dba070;
$theme-color-5: #f6c797;
$theme-color-6: #d6cdca;

$primary-color: #212121;
$secondary-color: #8f8f8f;
$muted-color: #909090;

$gradient-color-1 : #ff9c37;
$gradient-color-2 : #d15f12;
$gradient-color-3 : #f9a550;

$shadowOffsetsTop : 1 3 10 14 19;
$shadowBlursTop: 1.5 5 10 14 19;
$shadowOpacitiesTop: 0.04 0.1 0.19 0.25 0.3;

$shadowOffsetsBottom : 1 3 6 10 15;
$shadowBlursBottom: 3 6 6 5 6;
$shadowOpacitiesBottom: 0.04 0.1 0.2 0.22 0.22;

$logoPath: "/assets/img/logo-black.svg";
$logoPathMobile: "/assets/img/logo-mobile.svg";

@import "./mixins";
@import "./dore.style";
@import "./schedule.component";
@import "./customs"
