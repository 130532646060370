body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav-link:first-child {
  padding-left: 0;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 2000 !important;
}

.screen-list-image {
  position: relative;
}

.screen-list-image span.img-time {
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 10px;
  padding-left: 2px;
  padding-right: 2px;
  border: 1px solid;
  border-color: grey;
}

.screen-list-image span.img-delete {
  background-color: rgba(255, 255, 255, 0.9);
  color: #666;
  position: absolute;
  bottom: 10px;
  left: 20px;
  font-size: 12px;
  cursor: pointer;
  padding: 1px;
}

.screen-list-time-picker .rc-slider-mark-text {
  min-width: 35px;
}
