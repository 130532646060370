.schedule-container {
  user-select: none;
  display: flex;
  flex-direction: row;

  .schedule-label {
    @extend .text-muted;
    text-align: center;
    margin: 0;

    &.empty {
      min-height: 19px;
    }
  }

  .schedule-row {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.header {
      flex: 0;

      .schedule-label {
        width: 32px;
      }
    }
  }

  .schedule-item-label {
    @extend .text-muted;
    flex: 1;
    margin: 2px;
    text-align: center;
  }

  .schedule-item {
    cursor: pointer;
    flex: 1;
    height: 24px;
    margin: 2px;
    border-radius: 4px;
    background-color: $theme-color-6;

    &.selected {
      background-color: var(--green);
    }
  }
}

.schedule-legend-item {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: $theme-color-6;

  &.selected {
    background-color: var(--green);
  }
}

@media (min-width: 1100px) {

  .schedule-container {
    display: block;

    .schedule-row {
      flex-direction: row;
    }

    .schedule-label {
      width: 32px;
    }

    .schedule-item {
      width: auto;
      height: auto;
      flex: 1;

      &:before {
        content: '';
        float: left;
        padding-top: 100%;
      }
    }
  }
}
